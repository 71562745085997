<template>
  <div>
    <PageTemplate>
      <template slot="nav">
        <BasicNav>
          <template slot="nav-left">
            <div class="flex justify-start">
              <BackButton @click="goToTransactionLog"></BackButton>
            </div>
          </template>
          <template slot="nav-center">
            <div class="flex justify-center">
              <h1 class="text-3xl cash-purchase-title">Purchase Event Log Detail</h1>
            </div>
          </template>
        </BasicNav>
      </template>
      <template slot="body">
        <div class="grid grid-cols-1 mb-8">
          <div class="m-auto" v-if="errorFetching || isLoading">
            <LoadingCard v-if="isLoading" />
            <ErrorCard v-if="errorFetching" class="error-card" message="Cash transaction details not available"/>
          </div>
          <div v-else>
            <div class="w-full bg-white p-3 border-none">
              <BasicTable :columns="tableOneColumns" :rows="tableOneRows"  table-class="vgt-table bordered clean-borderless-table cash-detail-event-table" tr-class="cash-detail-event-table-rows" />
              <BasicTable :columns="tableTwoColumns" :rows="tableTwoRows"  table-class="vgt-table bordered clean-borderless-table cash-detail-transaction-table" tr-class="cash-detail-transaction-table-headers"/>
              <div>
                <p class="text-xl font-bold text-orange text-left m-3">Cash Entered </p>
                <div class="flex divide-x">
                  <DenominationValueCell
                      v-for="(value, index) in getAcceptorDenominations"
                      :key="`entered-${index}`"
                      :label="value.label"
                      :value="getCashEnteredByDenomination(value.denomination)"
                      :class="`flex-1 acceptor-${value.denomination.toLowerCase()}`" />
                </div>
              </div>
              <div>
                <p class="text-xl font-bold text-orange text-left m-3">Cash Dispensed </p>
                <div class="flex divide-x">
                  <DenominationValueCell
                      v-for="(value, index) in getDispenserDenominations"
                      :key="`entered-${index}`"
                      :label="value.label"
                      :value="getCashDispensedByDenomination(value.denomination)"
                      :class="`flex-1 dispenser-${value.denomination.toLowerCase()}`" />
                </div>
              </div>
              <TitleValueCard
                  title="Error Code:"
                  :value="getErrorCode"
                  class="border-b border-gray-200"
              />
              <TitleValueCard title="Error Message:" :value="getErrorMessage" />
            </div>
          </div>
        </div>
      </template>
    </PageTemplate>
  </div>
</template>

<script>
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import TitleValueCard from "../../components/Helpers/Cards/TitleValueCard";
import BasicTable from "../../components/Helpers/table/BasicTable";
import { mapActions, mapState } from "vuex";
import currency from "currency.js";
import DenominationValueCell from "../../components/Helpers/DenominationValueCell";
import TransactionLogDetail from "../../mixin/TransactionLogDetail";
import _ from "lodash";
import BackButton from "../../components/Helpers/Buttons/BackButton";
import LocalLogFormatters from "../../mixin/LocalLogFormatters";

export default {
  name: "CashTransactionDetail",
  mixins: [LocalLogFormatters, TransactionLogDetail],
  components: {BackButton, DenominationValueCell, TitleValueCard, ErrorCard, LoadingCard, BasicNav, PageTemplate, BasicTable},
  data() {
    return {
      isLoading: false,
      errorFetching: false,
    };
  },
  computed: {
    ...mapState("TransactionLogs", ["detailCashTransaction"]),
    tableOneColumns() {
      return [
        {
          label: "Event",
          field: "event",
          type: String,
          formatFn: this.formatLogType,
          thClass: "make-columns-even-thirds cash-detail-event-table-headers",
          tdClass: "cash-detail-event-table-event-data",
        },
        {
          label: "Date/Time",
          field: "createDateTime",
          type: String,
          formatFn: this.formatLogDate,
          thClass: "make-columns-even-thirds cash-detail-event-table-headers",
          tdClass: "cash-detail-event-table-dateTime-data",
        },
        {
          label: "Confirmation",
          field: "confirmationNumber",
          type: String,
          thClass: "make-columns-even-thirds cash-detail-event-table-headers",
          tdClass: "cash-detail-event-table-confirmation-data",
        },
      ];
    },
    tableOneRows() {
      return [
        {
          event: this.detailCashTransaction["event"],
          createDateTime: this.detailCashTransaction["createDateTime"],
          confirmationNumber: this.detailCashTransaction["confirmationNumber"],
        },
      ];
    },
    tableTwoColumns() {
      return [
        {
          label: "Transaction Amount",
          field: "transactionAmount",
          type: String,
          formatFn: this.currencyFormat,
          thClass:
            "make-columns-even-thirds cash-detail-transaction-table-headers",
          tdClass: "cash-detail-transaction-table-transactionAmount-data",
        },
        {
          label: "Payment Method",
          field: "paymentMethod",
          type: String,
          formatFn: this.paymentFormat,
          thClass:
            "make-columns-even-thirds cash-detail-transaction-table-headers",
          tdClass: "cash-detail-transaction-table-paymentMethod-data",
        },
        {
          label: "Printed",
          field: "printed",
          type: String,
          formatFn: this.formatPrintStatus,
          thClass:
            "make-columns-even-thirds cash-detail-transaction-table-headers",
          tdClass: "cash-detail-transaction-table-printed-data",
        },
      ];
    },
    tableTwoRows() {
      return [
        {
          transactionAmount: this.detailCashTransaction["purchaseCost"],
          paymentMethod: this.detailCashTransaction["paymentMethod"],
          printed: {
            total: this.detailCashTransaction["printTotalCount"],
            success: this.detailCashTransaction["printSuccessCount"],
          },
        },
      ];
    },

    getErrorCode() {
      return this.detailCashTransaction.statusCode || "None";
    },
    getErrorMessage() {
      return this.detailCashTransaction.productDescription
          || this.detailCashTransaction.paymentDescription || "None";
    },
  },
  methods: {
    ...mapActions("TransactionLogs", ["getTransactionLogCashDetails"]),
    async fetchData() {
      this.isLoading = true;
      let id = this.$route.params.id;
      try {
        await this.getTransactionLogCashDetails(id);
      } catch (e) {
        this.errorFetching = true;
      } finally {
        this.isLoading = false;
      }
    },
    getCashDispensedByDenomination(denomination) {
      const total = _.find(this.detailCashTransaction["cashDispensed"], {
        denomination: denomination,
      })?.total;
      return total ? currency(total).format() : currency(0).format();
    },
    getCashEnteredByDenomination(denomination) {
      const total = _.find(this.detailCashTransaction["cashEntered"], {
        denomination: denomination,
      })?.total;
      return total ? currency(total).format() : currency(0).format();
    },
    goToTransactionLog() {
      this.$router.push({ name: "TransactionLogs" });
    },
    currencyFormat(value) {
      return currency(value).format();
    },
    paymentFormat(value) {
      return value;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
.make-columns-even-thirds {
  width: 33% !important;
  background-color: white !important;
}
.clean-borderless-table {
  border: none !important;
  border-radius: 0px !important;
}
.make-columns-even-fiths {
  width: 20% !important;
  background-color: white !important;
  display: none !important;
}
.clean-borderless-table th:first-child {
  border-left: none !important;
}
.clean-borderless-table th:last-child {
  border-right: none !important;
}
.clean-borderless-table td:first-child {
  border-left: none !important;
}
.clean-borderless-table td:last-child {
  border-right: none !important;
}
</style>
