<template>
  <div class="bg-white">
    <div class="card-header flex  p-3">
      <div class="w-full flex justify-start content-center ">
        <h1 class="text-xl font-medium text-orange whitespace-nowrap mr-2">{{title}}</h1>
        <span class="text-gray-500 font-medium text-left p-1">{{value}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleValueCard",
  props: {
    title: {
      type: String,
      default: "",
      required: false
    },
    value: {
      type: String,
      default: "",
      required: false
    },
  }
}
</script>
