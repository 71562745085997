<template>
  <vue-good-table
      :columns="columns"
      :rows="rows"
      :sort-options="
      {
        enabled: sortable
      }"
      :styleClass="tableClass"
      theme="polar-bear"
      :row-style-class="trClass"
    >
    <template slot="table-column" slot-scope="props">
       <span class="text-orange  block p-0 text-lg w-100 h-100">
          {{props.column.label}}
       </span>
    </template>
  </vue-good-table>
</template>

<script>
import {VueGoodTable} from "vue-good-table";
export default {
  props: {
    rows: {
      required: true,
    },
    columns: {
      required: true,
    },
    sortable: {
      required: false,
      type: Boolean,
      default: false,
    },
    tableClass: {
      required: false,
      type: String,
      default: "vgt-table bordered",
    },
    trClass: {
      required: false,
      type: String,
      default:'',
    }
  },
  name: "BasicTable",
  components: {VueGoodTable}
}
</script>